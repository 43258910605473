import React, {useEffect, useState} from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import TextField from './TextField';
import CheckboxOption from './CheckboxOption';
import RadioOption from './RadioOption';
import SuccessMessage from './SuccessMessage';
import NewsletterImage from './newsletter.png';

const NewsletterSubscriptionForm = () => {
    const { t, i18n } = useTranslation();
    const [formSuccess, setFormSuccess] = useState(false); // Success message state
    const [startTime, setStartTime] = useState(null);

    useEffect(() => {
        setStartTime(Date.now());
    }, []);

    const initialValues = React.useMemo(
        () => ({
            email: '',
            preferredLanguage: i18n.language || 'en',
            subscription_list_cat_general: true,
            subscription_list_cat_bus_updates: false,
            dataProtectionConsent: false,
            subscription_list: '', // Virtual field
            hiddenField: '', // Honeypot field
        }),
        [i18n.language]
    );

    const validationSchema = React.useMemo(
        () =>
            Yup.object().shape({
                email: Yup.string()
                    .email(t('newsletter_error_valid_email'))
                    .required(t('newsletter_error_email_required')),
                preferredLanguage: Yup.string().required(
                    t('newsletter_error_preferred_language_required')
                ),
                dataProtectionConsent: Yup.bool().oneOf(
                    [true],
                    t('newsletter_error_data_protection_consent_required')
                ),
                subscription_list_cat_general: Yup.bool(),
                subscription_list_cat_bus_updates: Yup.bool(),
                subscription_list: Yup.string() // Virtual field
                    .test('subscription-lists', null, function () {
                        const {
                            subscription_list_cat_general,
                            subscription_list_cat_bus_updates,
                        } = this.parent;
                        if (subscription_list_cat_general || subscription_list_cat_bus_updates) {
                            return true;
                        }
                        return this.createError({
                            path: 'subscription_list',
                            message: t('newsletter_error_subscription_required'),
                        });
                    }),
                hiddenField: Yup.string(),
            }),
        [t]
    );

    const handleSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {

        const currentTime = Date.now();
        const timeTaken = currentTime - startTime;

        // Set a minimum time threshold (e.g., 3 seconds)
        const MIN_TIME = 3000; // in milliseconds

        if (timeTaken < MIN_TIME) {
            // Possible bot submission
            console.warn('Form submitted too quickly, possible bot submission.');
            setSubmitting(false);
            // Optionally, you can show an error message or silently ignore
            return;
        }


        if (values.hiddenField) {
            setSubmitting(false);
            return;
        }
        try {
            const data = {
                email: values.email,
                cat_general: values.subscription_list_cat_general,
                cat_bus_updates: values.subscription_list_cat_bus_updates,
                langcode: values.preferredLanguage,
                hiddenField: values.hiddenField
            };
            const response = await fetch('/api/save-newsletter-subscriber', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(data),
            });

            if (response.ok) {
                setFormSuccess(true);
                console.log('formSuccess set to true');
                setTimeout(() => {
                    setFormSuccess(false);
                    resetForm();
                    setStartTime(null); // Reset start time here
                }, 5000);
            } else {
                const errorText = await response.text();
                setStatus({ serverError: t('newsletter_submission_error') });
                console.error('Error submitting form:', errorText);
            }
        } catch (error) {
            setStatus({ serverError: t('newsletter_submission_error') });
            console.error('Error submitting form:', error);
        } finally {
            setSubmitting(false);
        }
    };

    const radioOptions = ['fr', 'de'];

    return (
        <div className="mx-auto w-full max-w-screen-md my-10  px-5 pb-10">

       <img src={NewsletterImage} />

            {formSuccess ? (
                <SuccessMessage />
            ) : (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ status, isSubmitting, values, errors, submitCount }) => (
                        <Form>
                            <Field
                                type="text"
                                name="hiddenField"
                                style={{ display: 'none' }}
                                tabIndex="-1"
                                autoComplete="off"
                            />
                            <div className="w-full bg-white shadow-lg my-10 rounded-2xl">
                                <div className="p-5 md:p-10 pb-0 md:pb-0 ">
                                    <h1 className="text-2xl text-center md:text-4xl font-bold mb-5 ">
                                        {t('heading_newsletter_subscription')}

                                    </h1>
                                    <div className="mb-10 text-sm text-center md:text-base font-light text-gray-800 ">
                                        {t('newsletter_description')}
                                    </div>
                                </div>

                                <div className="flex flex-col divide-y divide-dashed divide-gray-400">
                                    {/* Email Field */}
                                    <div className="w-full p-5 md:p-10 mb-5 py-5">
                                        <Field
                                            name="email"
                                            type="email"
                                            component={TextField}
                                            label={t('newsletter_email')}
                                            placeholder={t('newsletter_email_placeholder')}
                                            autoComplete="off"
                                                   />
                                    </div>

                                    {/* Subscription Lists */}
                                    <div className="w-full p-5 md:p-10 py-10">
                                        <h3 className="text-lg md:text-xl font-bold required mb-2">
                                            {t('subscription_lists_heading')} (
                                            {(values.subscription_list_cat_general ? 1 : 0) +
                                                (values.subscription_list_cat_bus_updates ? 1 : 0)}
                                            /2)
                                        </h3>
                                        <div className="text-sm font-light text-gray-800 mb-10">
                                            {t('subscription_lists_descripiton')}
                                        </div>

                                        <div className="flex flex-col gap-5 w-full mb-5">
                                            <Field
                                                name="subscription_list_cat_general"
                                                component={CheckboxOption}
                                                id="subscription_list_cat_general"
                                                label={t('subscription_list_cat_general_label')}
                                                description={t('subscription_list_cat_general_description')}
                                                frequency={t('subscription_list_cat_general_frequency')}
                                                alignTop={true}
                                            />

                                            <Field
                                                name="subscription_list_cat_bus_updates"
                                                component={CheckboxOption}
                                                id="subscription_list_cat_bus_updates"
                                                label={t('subscription_list_cat_bus_updates_label')}
                                                description={t('subscription_list_cat_bus_updates_description')}
                                                frequency={t('subscription_list_cat_bus_updates_frequency')}
                                                alignTop={true}
                                            />
                                        </div>
                                        <ErrorMessage name="subscription_list">
                                            {(msg) => (
                                                <div className="text-red-600 font-bold">{msg}</div>
                                            )}
                                        </ErrorMessage>
                                    </div>

                                    {/* Preferred Language */}
                                    <div className="w-full p-5 md:p-10 py-10">
                                        <h3 className="text-lg md:text-xl font-bold required mb-2">
                                            {t('preferred_language_heading')}
                                        </h3>
                                        <div className="text-sm font-light text-gray-800 mb-5">
                                            {t('preferred_language_descripiton')}
                                        </div>

                                        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-3">
                                            {radioOptions.map((option) => (
                                                <Field
                                                    key={option}
                                                    name="preferredLanguage"
                                                    component={RadioOption}
                                                    id={option}
                                                    value={option}
                                                    label={t(`${option}_title`)}
                                                />
                                            ))}
                                        </div>
                                        <ErrorMessage name="preferredLanguage">
                                            {(msg) => (
                                                <div className="text-red-600 font-bold mt-2">{msg}</div>
                                            )}
                                        </ErrorMessage>
                                    </div>

                                    {/* Data Protection Consent */}
                                    <div className="p-5 md:p-10 py-10">
                                        <h3 className="text-lg md:text-xl font-bold required mb-2">
                                            {t('data_protection_heading')}
                                        </h3>
                                        <div className="flex flex-col gap-5 w-full">
                                            <div className="w-full p-3 md:p-5 bg-gray-100 text-sm">
                                                <Trans
                                                    i18nKey={'data_protection'}
                                                    components={{
                                                        h3: <h3/>,
                                                        p: <p/>,
                                                        strong: <strong/>,
                                                        0: (
                                                            <a
                                                                href="https://mailjet.com"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            />
                                                        ),
                                                    }}
                                                />
                                            </div>
                                            <div className="flex items-center gap-5 w-full">
                                                <Field
                                                    name="dataProtectionConsent"
                                                    component={CheckboxOption}
                                                    id="dataProtectionConsent"
                                                    label={t('newsletter_data_protection_consent_title')}
                                                    required={true}
                                                />
                                            </div>
                                            <ErrorMessage name="dataProtectionConsent">
                                                {(msg) => (
                                                    <div className="text-red-600 font-bold">{msg}</div>
                                                )}
                                            </ErrorMessage>
                                        </div>

                                        {/* Submit Button and Error Summary */}
                                        <div
                                            className="flex flex-wrap items-center justify-end gap-2 md:gap-5 mt-5 w-full">
                                            {status && status.serverError && (
                                                <div
                                                    className="flex items-center gap-3 bg-red-500 text-white p-3 rounded-lg mb-5 md:mb-0">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="1.5"
                                                        stroke="currentColor"
                                                        className="w-6 md:w-10 flex-none"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
                                                        />
                                                    </svg>

                                                    <div className="grow">{status.serverError}</div>
                                                </div>
                                            )}

                                            {submitCount > 0 && Object.keys(errors).length > 0 && (
                                                <div
                                                    className="error-summary mt-5 p-4 bg-red-100 text-red-700 rounded w-full mb-5">
                                                    <h4 className="flex items-center gap-3 font-bold mb-2">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            className="size-8"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
                                                            />
                                                        </svg>
                                                        {t('error_summary_title')}
                                                    </h4>
                                                    <ul className="list-disc list-inside">
                                                        {Object.entries(errors).map(([field, error]) => (
                                                            <li key={field}>
                                                                <span className="text-red-700">{error}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}

                                            <button
                                                type="submit"
                                                className="transition-transform duration-300 inline-flex items-center justify-center gap-2 text-md text-white md:text-xl font-medium px-3 py-2 bg-primary border-2 border-primary rounded-2xl hover:scale-110"
                                                disabled={isSubmitting}
                                            >
                                                {t('newsletter_submit')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default NewsletterSubscriptionForm;
